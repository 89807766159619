<template>
  <div class="background red-background"
       @click="backgroundClicked">
    <div class="content-cell">
      <div class="author">Bruce Glassco</div>
      <img class="logo" :src="require('@/assets/logo_' + this.$i18n.locale + '.png')" alt="">
      <div class="settings-area" v-if="extensionEnabled">
        <div class="settings-item">
          <input type="checkbox" id="cursedHoard" :checked="cursedHoard" @change="toggleCursedHoard"/>
          <label for="cursedHoard">{{ $t('enableCursedHoard') }}</label>
        </div>
        <div class="settings-item" v-if="false">
          <input type="checkbox" id="cursedItems" :checked="cursedItemsEnabled" @change="toggleCursedItems"/>
          <label for="cursedItems">{{ $t('enableCursedItems') }}</label>
        </div>
      </div>
      <div class="button-area">
        <div class="button" @click="newGameClicked">{{ $t('scoreAGame') }}</div>
        <div class="statistics-link"
             @click="$router.push({name: 'viewStatistics'})"
             v-if="allGames.length > 0">
          {{ $t('statistics') }}
        </div>
      </div>
    </div>
    <div class="imprint-link"
         @click="$router.push({name: 'imprint'})"
         v-if="$i18n.locale === 'de'">
      {{ $t('imprintAndDataSecurity') }}
    </div>
    <div id="beta">BETA</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      clickCount: 0
    }
  },
  computed: {
    ...mapGetters(['cursedHoard', 'cursedItemsEnabled', 'extensionEnabled', 'allGames', 'consent'])
  },
  methods: {
    ...mapActions(['reset', 'toggleCursedHoard', 'toggleCursedItems', 'enableExtension']),
    newGameClicked() {
      if (!this.consent.consent)
        this.showInfo(this.$t('cookies.consentRequired'))
      else
        this.reset().then(() => this.$router.push({name: 'addToPile2'}))
    },
    backgroundClicked() {
      this.clickCount++
      if (this.clickCount >= 5) {
        this.enableExtension()
      }
    }
  }
}
</script>

<style lang="scss">
.background {
  position: fixed;
  display: table;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
}

.imprint-link {
  position: fixed;
  bottom: 12px;
  left: 12px;
  color: #FFFFFF66;
  cursor: pointer;
  font-size: smaller;
}

.statistics-link {
  position: relative;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  color: #FFFFFF66;
  cursor: pointer;
  font-size: smaller;
  margin-top: 0.6rem;
}

.author {
  font-family: Luminari;
}

.content-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.logo {
  width: 250px;
}

.button-area {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: 0.6rem;
  justify-content: center;
}

.settings-area {
  font-family: Oswald;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.settings-item {
  display: flex;
  align-items: center;
  margin: 0.3rem;
}

#beta {
  position: fixed;
  bottom: 5%;
  right: 3%;
  color: #FFFFFF66;
  font-size: xx-large;
  font-weight: bolder;
  // transform: rotateY(0deg) rotate(-45deg);
}
</style>
